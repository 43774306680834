import { useEffect, useRef, useState } from "react";
import writingService from "../../services/writingService";
import { useAuth } from "../../contexts/authContext";
import TextEditor from "../../components/cms/editor";
import { useParams } from "react-router-dom";
import tagService from "../../services/tagService";
import TagSelect from "./tagSelect";
import UploadImage from "../../components/upload-image";

export default function WritingUpdatePage() {
  const { id } = useParams();
  const { user } = useAuth();
  const ref = useRef(null)
  const [form, setForm] = useState({
    title: "",
    content: "",
    name: "",
    thumbnail: "",
    meta_keyword: "",
    meta_description: "",
    status: "active"
  })
  const [tags, setTags] = useState([])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const fetchWriting = async () => {
    if (!id) return;
    try {
      const data = await writingService.getWriting(id);
      if (!data) return;
      setForm({
        title: data?.title || "",
        content: data?.content || "",
        name: data?.name || "",
        thumbnail: data?.thumbnail || '',
        meta_keyword: data?.meta_keyword || '',
        meta_description: data?.meta_description || '',
        active: data?.status || 'active'
      });
      setTags(data?.tags.map(tag => ({
        value: tag._id,
        label: tag.name
      })))
      if (ref?.current) {
        ref.current.checked = data?.status === 'active'
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onStatusToggled = async (event) => {
    const target = event.target
    const value = target.checked
    setForm((prev) => ({ ...prev, status: value ? 'active' : 'inactive' }))
  }

  const handleCreate = async () => {
    // user && createArticle(form, user);
    try {
      const _tags = []
      for (let i = 0; i < tags.length; i++) {
        const t = tags[i]
        if (t.__isNew__) {
          const a = await tagService.createTag(t.value)
          _tags.push({
            name: a.name,
            value: a._id
          })
        } else {
          _tags.push(t)
        }
      }
      
      const data = await writingService.updateWriting(id, {
        ...form,
        tags: _tags.map(x => x.value)
      });
      alert(data.message);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchWriting();
  }, [id]);

  return (
    <div className="container">
      <div className="flex space-between gap-x-6 mb-2">
        <div className="flex-1 flex flex-col gap-y-2">
          <input
            type="text"
            className="w-full p-2 border rounded"
            name="title"
            value={form.title}
            onChange={handleInputChange}
            placeholder="Title"
          />
          <input
            name="name"
            type="text"
            className="w-full p-2 border rounded"
            onChange={handleInputChange}
            value={form.name}
            placeholder="Name"
          />
          <input
            name="meta_keyword"
            type="text"
            className="w-full p-2 border rounded"
            onChange={handleInputChange}
            value={form.meta_keyword}
            placeholder="Meta keyword (optional)"
          />
          <input
            name="meta_description"
            type="text"
            className="w-full p-2 border rounded"
            onChange={handleInputChange}
            value={form.meta_description}
            placeholder="Meta description (optional)"
          />
          <div className="flex mb-2">
            <TagSelect value={tags} setValue={setTags} />
          </div>
        </div>
        <div className="w-full max-w-[360px]">
          <UploadImage name="thumbnail" className="h-full" value={form.thumbnail} onChange={handleInputChange}/>
        </div>
      </div>
      <TextEditor
        name="content"
        onChange={(value) => setForm((prev) => ({ ...prev, content: value }))}
        value={form.content}
      />
      <div className="mt-8 mb-8">
        <label className="relative inline-flex items-center cursor-pointer">
          <input onChange={onStatusToggled} ref={ref} type="checkbox" value="" className="sr-only peer" />
          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          <span className="ml-3 text-sm font-medium text-2xl">Status</span>
        </label>
      </div>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
        onClick={user && handleCreate}
      >
        Save
      </button>
    </div>
  );
}
