import { useEffect } from "react";
import "./App.css";
import AppRoute from "./route";
import { useAuth } from "./contexts/authContext";
import authService from "./services/authService";
import { AUTH_TOKEN } from "./config/appConfig";
import "./css/prism.css";

function App() {
  const { user, login } = useAuth();
  
  return <AppRoute />;
}

export default App;
