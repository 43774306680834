import service from "./axiosConfig";

const authService = {
  login: (data) => {
    return service.post("/auth/login", data);
  },
  register: (data) => {
    return service.post("/auth/register", data);
  },
  me: () => {
    return service.get("/auth/me");
  },
};

export default authService;
