import { useState } from "react";
import tagService from "../../services/tagService";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable'

const opts = [
  { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
  { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' },
]

export default function TagSelect ({ value, setValue }) {
  const promiseOptions = async (inputValue) => {
    try {
       const opts = await tagService.getTags()
       return opts.map(opt => ({
        value: opt._id,
        label: opt.name
       }))
    } catch (error) {
      console.log(error)
      return []
    }
  }

  return (
    <AsyncCreatableSelect
      placeholder="Choose tags"
      isMulti
      name="tags"
      defaultOptions
      value={value}
      loadOptions={promiseOptions}
      onChange={setValue}
      cacheOptions
      className="w-full"
    />
  )
}