import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import writingService from "../../services/writingService";

import { hightlightContent } from "../../utils/highlight";

export default function WritingPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [article, setArticle] = useState({});

  const fetchArticle = async () => {
    if (!id) return;
    try {
      const data = await writingService.getWriting(id);
      if (!data) return;
      setArticle(data);
    } catch (error) {
      console.log(article);
    }
  };

  const deleteWriting = async (id) => {
    try {
      const data = await writingService.deleteWriting(id);
      if (!data) return;
      alert(data.message);
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchArticle();
  }, [id]);

  const displayHTML = useMemo(() => {
    const str = article?.content;

    return hightlightContent(str);
  }, [article?.content]);

  return (
    <>
      <section>
        <div className="container">
          <article>
            <h1 className="text-center">{article?.title}</h1>
            <div className="flex justify-end items-center mb-6">
              <p className="text-right">Tác giả: {article?.name}</p>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2 ml-4"
                onClick={() => navigate(`/writing/update/${article?._id}`)}
              >
                Chỉnh sửa
              </button>

              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-2 ml-4"
                onClick={() => deleteWriting(article?._id)}
              >
                Xoá
              </button>
            </div>

            <div
              dangerouslySetInnerHTML={{
                __html: displayHTML || "",
              }}
            />
          </article>
        </div>
      </section>
    </>
  );
}
