import service from "./axiosConfig";

const tagService = {
  getTags: async (params) => {
    const { data } = await service.get('/api/tags', {
      params: params
    })
    return data
  },
  createTag: async (name) => {
    const data = await service.post('/api/tags', { name })
    console.log(data)
    return data
  },
};

export default tagService;
