import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import { useEffect } from "react";
import { AUTH_TOKEN } from "../config/appConfig";
import authService from "../services/authService";

export default function PrivateRoute({ children }) {
  const navigate = useNavigate()
  const { user, login } = useAuth();

  useEffect(() => {
    if (!user) {
      const token = localStorage.getItem(AUTH_TOKEN);
      if (token) {
        (async () => {
          const infoUser = await authService.me();
          login(infoUser);
        })();
      } else {
        navigate('/login')
      }
    }
  }, [user]);

  return children
}
