// import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import uploadService from "../../services/uploadService";

class UploadAdapter {
	constructor(loader) {
		this.loader = loader;
		this.loader.file.then(pic => (this.file = pic));

		this.upload();
	}

	// Starts the upload process.
	upload() {
		const fd = new FormData();
    if (!this.file) return
		fd.append("file", this.file);

		return new Promise((resolve, reject) => {
      uploadService.uploadImage(fd).then(res => {
        const { success, url } = res
        if (success) {
          console.log(url)
          resolve({ default: url })
        }
        reject()
      }).catch(err => reject(err))
		});
	}
}

export default function TextEditor({ value, onChange = (_) => {} }) {
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new UploadAdapter(loader);
    };
  }

  return (
    <CKEditor
      config={{
        extraPlugins: [uploadPlugin],
      }}
      editor={Editor}
      data={value}
      onChange={(_, editor) => {
        onChange(editor.getData());
      }}
    />
  );
}
