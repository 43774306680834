import React, { useContext, useState } from "react";

const initialState = {
  user: null,
  login: (_user) => {},
  logout: () => {},
};

const AuthContext = React.createContext(initialState);
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = (props) => {
  const { children } = props;
  const [user, setUser] = useState(null);

  const login = (_user) => {
    setUser(_user);
  };
  const logout = async () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
