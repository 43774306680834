import { createBrowserRouter } from "react-router-dom";
import Layout from "../layout";
import Login from "../pages/login";
import PrivateRoute from "./PrivateRoute";
import RouterLogin from "./RouterLogin";
import Home from "../pages/home";
import WritingPage from "../pages/writing";
import WritingCreatePage from "../pages/writing/create";
import WritingUpdatePage from "../pages/writing/edit";

export const Routes = createBrowserRouter([
  {
    path: "",
    element: (
      <PrivateRoute>
        <Layout />
      </PrivateRoute>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/writing/:id",
        element: <WritingPage />,
      },
      {
        path: "/writing/create",
        element: <WritingCreatePage />,
      },
      {
        path: "/writing/update/:id",
        element: <WritingUpdatePage />,
      },
    ],
  },
  {
    path: "/login",
    element: (
      <RouterLogin>
        <Login />
      </RouterLogin>
    ),
  },
]);
