import service from "./axiosConfig";

const writingService = {
  getWriting: (id) => {
    return id ? service.get(`/api/writing/${id}`) : service.get("/api/writing?createAt=-1");
  },
  createWriting: (data) => {
    return service.post("/api/writing", data);
  },
  updateWriting: (id, data) => {
    return service.put(`/api/writing/${id}`, data);
  },
  deleteWriting: async (id) => {
    const { data } = await service.delete(`/api/writing/${id}`)
    return data
  },
};

export default writingService;
