import React, { useEffect, useState } from "react";
import writingService from "../../services/writingService";
import moment from "moment/moment";
import { Link } from "react-router-dom";

const Home = () => {
  const [writing, setWriting] = useState([]);

  const fetchArticles = async () => {
    try {
      const data = await writingService.getWriting();
      if (!data) return;
      const { writings } = data;
      setWriting(writings.sort((a,b) => {
        return new Date(b.createAt) - new Date(a.createAt);
      }));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchArticles();
  }, []);
  return (
    <section>
      <div className="container">
        <div className="flex flex-col gap-y-4">
          {writing?.map((item) => (
            <div
              key={item._id}
              className={`border border-primary p-4 rounded ${item.status === 'inactive' ? 'bg-stone-200' : ''}`}
            >
              <p className="text-sm">
                <span className="hover:underline cursor-pointer text-muted-foreground">
                  {item.name}
                </span>
                <span className="ml-2">
                  lúc {moment(item.createAt).format("HH:mm")} ngày{" "}
                  {moment(item.createAt).format("DD-MM-YYYY")}
                </span>
              </p>
              <Link to={`/writing/${item._id}`} className="text-xl cursor-pointer hover:text-foreground">
                {item.title || item._id}
              </Link>
              <p className="mt-1">
                {item.tags?.map(tag => (
                  <span className="text-sm leading-tight px-1 mr-2 bg-[#f4f4f5] hover:bg-[#eaeaea] border border-[#e9e9eb] text-[#909399] rounded cursor-pointer">{tag.name}</span>
                ))}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Home;
