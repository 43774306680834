import Prism from "prismjs";
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-cpp'
import 'prismjs/components/prism-css'
import 'prismjs/components/prism-cshtml'

const supportedLang = [
  'javascript',
  'typescript',
  'c',
  'cpp'
]

export const hightlightContent = content => {
  const str = content
  const regex = new RegExp(/<pre>(.*?)<\/pre>/, "gms");

  const matches = str?.replace(regex, function (match, content) {
    const langArr = /language-(\w+)/.exec(match || "") || [];
    const _lang = langArr.length === 2 ? langArr[1] : 'plaintext'
    const lang = supportedLang.includes(_lang) ? _lang : 'plaintext'
    const codeBlockEle = document.createElement('code')
    codeBlockEle.innerHTML = match
    return `<pre class="language-${
      lang
    }"><code class=language-${lang}">${Prism.highlight(codeBlockEle.innerText, Prism.languages?.[lang], lang)}</code></pre>`;
  });
  return matches;
}