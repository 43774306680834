import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/authContext";

const Header = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  return (
    <header>
      <nav className="bg-main">
        <div className="container">
          <div className="flex justify-between items-center py-4">
            <img
              src="/logo.png"
              height={60}
              className="h-12 cursor-pointer"
              onClick={() => navigate("/")}
              alt=""
            />

            {user ? (
              <>
                <div className="flex space-x-10 items-center text-white">
                  <p
                    className="text-secondary cursor-pointer"
                    onClick={() => navigate("/writing/create")}
                  >
                    Thêm bài viết
                  </p>
                  <button variant="outline">{user.displayName}</button>
                </div>
              </>
            ) : (
              <button className="text-white" onClick={() => Navigate("/login")}>
                Login
              </button>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
