import uploadService from "../../services/uploadService"

export default function UploadImage ({
  name,
  value,
  onChange = () => {},
  className
}) {
  const handleImageChange = async (e) => {
    const file = e.target.files?.length && e.target.files[0]
    if (file && /^image\//.test(file.type)) {
      const formData = new FormData()
      formData.append('file', file)

      const { success, url } = await uploadService.uploadImage(formData)
      if (success) {
        onChange && onChange({
          target: {
            name: name,
            value: url
          }
        })
      }
    }
  }

  return (
    <div className="relative aspect-[3/2] border-2 rounded flex items-center justify-center hover:bg-black/10">
      {value && (
        <img
          src={value}
          className="rounded-xl p-2 w-full aspect-[3/2] relative"
          style={{
            objectFit: "contain",
          }}
          alt=""
        />
      )}
      <p className="text-gray-300 absolute text-center -z-10">Drop thumbnail here</p>
      <input
        type="file"
        accept="image/*"
        className="absolute inset-0 cursor-pointer opacity-0"
        onChange={handleImageChange}
      />
    </div>
  );
}